<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{ $route.name }}</h5>
        </b-col>
        <!-- <b-col cols="2" class="text-right">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" href="#/order/create">Tambah Data</b-btn>
          </b-button-group>
        </b-col> -->
        <b-col cols="2" class="text-right">
          <!-- <b-button-group class="add-button-group">
            <b-btn variant="primary" href="#/order/create">Tambah Jama`ah</b-btn>
          </b-button-group> -->
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col md="10">
              <b-row>
                <b-col cols="3">
                  <form-group-input
                    ref="filter-pilgrim"
                    type="text"
                    placeholder="Cari nama jama'ah"
                    :need-label="true"
                    label="Jama'ah"
                    v-model="filter.pilgrim"
                    id="filter-pilgrim"
                  />
                </b-col>
                <!-- <b-col cols="3">
                  <form-group-input
                    ref="filter-phone_number"
                    type="text"
                    placeholder="Cari nomor telepon"
                    :need-label="true"
                    label="Nomor Telepon"
                    v-model="filter.phone_number"
                    id="filter-phone_number"
                  />
                </b-col> -->
                <!-- <b-col cols="3">
                  <form-group-input
                    ref="filter-nomor_va"
                    type="text"
                    placeholder="Cari nomor VA"
                    :need-label="true"
                    label="Nomor VA"
                    v-model="filter.nomor_va"
                    id="filter-nomor_va"
                  />
                </b-col> -->
                <b-col cols="3">
                  <label for="filter-date-transaction" class="form-label"
                    >Tanggal Transaksi</label
                  >
                  <date-picker
                    class="form-control"
                    format="DD-MM-YYYY"
                    placeholder="Cari Tgl Transaksi"
                    :need-label="true"
                    range
                    v-model="fake_filter.datepicker"
                  />
                </b-col>
                <b-col
                  cols="3"
                  v-if="
                    $store.getters['account/accountData'].role !=
                      'customer service'
                  "
                >
                  <b-form-group
                    id="fieldset-1"
                    label="Status"
                    label-for="input-1"
                    class="form-label"
                  >
                    <b-form-select
                      v-model="filter.status"
                      :options="options.status"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    id="fieldset-1"
                    label="Paket"
                    label-for="input-1"
                    class="form-label"
                  >
                    <b-form-select
                      v-model="filter.package_id"
                      :options="options.packages"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="2">
              <b-row class="pl-3">
                <b-col cols="12">
                  <label class="form-label">&nbsp;</label><br />
                  <b-btn type="submit" variant="primary">Cari</b-btn>
                  <b-btn
                    type="button"
                    @click.prevent="clearForm"
                    variant="warning"
                    class="ml-3 text-white"
                    >Clear</b-btn
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end px-5">
        <b-button variant="success" @click="doExportExcel">Export</b-button>
      </b-col>
    </b-row>
    <paginated-table
      ref="dataTable"
      aksi-dot
      :delete-reason="whoAmI('admin')"
      :can-delete="
        $store.getters['account/accountData'].role === 'admin' ? true : false
      "
      :can-edit="
        (() => {
          if (whoAmI('admin') || whoAmI('customer service')) return true;
          return false;
        })()
      "
      :data-url="apiUrl.order"
      :data-columns="
        $store.getters['account/accountData'].role === 'admin'
          ? columns
          : columns_customer_service
      "
      :dataParams="dataParams"
      :defaultDataParams="filter"
      v-if="show"
    >
      <template slot="addActionOption" slot-scope="defaultSlotScope">
        <span @click="print(defaultSlotScope.colData)"
          >Print Surat Pernyataan</span
        >
        <span
          @click="
            () => doPrintPaymentInvoice(defaultSlotScope.colData.order_no)
          "
          >Print Bukti Pembayaran</span
        >
        <span
          v-if="!whoAmI('admin')"
          @click="throwData(defaultSlotScope.colData.id, 1)"
          >Lempar Data</span
        >
      </template>
      <template slot="content_status" slot-scope="defaultSlotScope">
        <div class="text-center">
          <span
            :class="[
              'badge',
              defaultSlotScope.colData.status == 'unpaid'
                ? 'badge-v1-danger'
                : 'badge-v1-success',
            ]"
          >
            {{ defaultSlotScope.colData.status }}
          </span>
        </div>
      </template>
      <template slot="addActionButtons" slot-scope="defaultSlotScope">
        <div class="d-flex">
          <b-btn
            style="width: 38px; height: 35px"
            @click="print(defaultSlotScope.colData)"
            class="d-flex justify-content-center mr-1 ml-1"
            variant="success"
            >P</b-btn
          >
          <b-btn
            style="width: 38px; height: 35px; color: white"
            @click="throwData(defaultSlotScope.colData.id)"
            class="d-flex justify-content-center mr-1 ml-1"
            v-if="whoAmI('customer service') || whoAmI('back office')"
            variant="secondary"
          >
            <b-icon icon="arrow-right-short"></b-icon>
          </b-btn>
        </div>
      </template>
      <template slot="content_user" slot-scope="defaultSlotScope">
        <!-- <b-link :to="'/master-data/user/' + defaultSlotScope.colData.user_id">{{
          defaultSlotScope.colData.user.name
        }}</b-link> -->
        <span>{{
          defaultSlotScope.colData.pilgrims
            ? defaultSlotScope.colData.pilgrims[0].name
            : "-"
        }}</span>
      </template>
      <template slot="content_phone_number" slot-scope="defaultSlotScope">
        <span>{{
          defaultSlotScope.colData.pilgrims
            ? defaultSlotScope.colData.pilgrims[0].phone_number
            : "-"
        }}</span>
      </template>
      <template slot="content_package" slot-scope="defaultSlotScope">
        <span>{{
          defaultSlotScope.colData.details[0].orderdetailable.name
        }}</span>
      </template>
      <template slot="content_package_name" slot-scope="defaultSlotScope">
        <span>{{
          defaultSlotScope.colData.details[0].orderdetailable.name
        }}</span>
      </template>
      <template slot="content_user_nik" slot-scope="defaultSlotScope">
        <span>{{
          defaultSlotScope.colData.pilgrims
            ? defaultSlotScope.colData.pilgrims[0].nik
            : "-"
        }}</span>
      </template>
      <template slot="content_buyer_note" slot-scope="defaultSlotScope">
        <span>
          {{
            defaultSlotScope.colData.buyer_note
              ? defaultSlotScope.colData.buyer_note
              : "-"
          }}
        </span>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from "@/components/PaginatedTable.vue";
import formGroupInput from "@/components/FormGroupInput.vue";
import Package from "@/models/Package.js";
import Order from "@/models/Order.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

const orderModel = new Order();
const packageModel = new Package();

export default {
  components: {
    formGroupInput,
    paginatedTable,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      apiUrl: {
        order: orderModel.endpoint,
      },
      show: true,
      columns: [
        {
          prop: "created_at",
          label: "Tgl. Order",
          resizable: true,
          align: "left",
          sortable: false,
          minWidth: 200,
          formatThisDate: true,
        },
        {
          prop: "order_no",
          label: "Nomor Order",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        // {
        //   prop: 'user',
        //   label: 'Nama Order',
        //   resizable: true,
        //   sortable: false,
        //   minWidth: 100
        // },
        {
          prop: "user",
          label: "Jama'ah",
          resizable: true,
          align: "center",
          sortable: false,
          minWidth: 200,
        },
        {
          prop: "nomor_va",
          label: "VA Nomor",
          resizable: true,
          align: "center",
          sortable: false,
          minWidth: 150,
        },
        {
          prop: "package",
          label: "Paket Pilihan",
          sortable: false,
          minWidth: 200,
        },
        {
          prop: "user_nik",
          label: "NIK",
          resizable: true,
          align: "center",
          sortable: false,
          minWidth: 200,
        },
        {
          prop: "phone_number",
          label: "Nomor Handphone",
          resizable: true,
          align: "center",
          sortable: false,
          minWidth: 150,
        },
        {
          prop: "status",
          label: "Status",
          align: "center",
          resizable: true,
          sortable: false,
          minWidth: 100,
        },
        {
          prop: "sub_total",
          label: "Sub Total",
          resizable: true,
          align: "right",
          needCurrency: true,
          sortable: false,
          minWidth: 200,
        },
        {
          prop: "discount",
          label: "Diskon",
          align: "right",
          resizable: true,
          sortable: false,
          minWidth: 100,
        },
        {
          prop: "total",
          label: "Total",
          align: "right",
          resizable: true,
          sortable: false,
          needCurrency: true,
          minWidth: 200,
        },
        {
          prop: "total_paid",
          label: "Dibayar",
          align: "right",
          resizable: true,
          sortable: false,
          needCurrency: true,
          minWidth: 200,
        },
      ],
      options: {
        status: [
          { value: null, text: "Semua Status" },
          { value: "paid", text: "paid" },
          { value: "partial", text: "partial" },
          { value: "unpaid", text: "unpaid" },
          { value: "canceled", text: "canceled" },
        ],
        packages: [{ value: null, text: "Semua Paket" }],
      },
      columns_customer_service: [
        {
          prop: "created_at",
          label: "Tgl. Order",
          resizable: true,
          align: "left",
          sortable: false,
          minWidth: 200,
          formatThisDate: true,
        },
        {
          prop: "order_no",
          label: "Nomor Order",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        {
          prop: "package_name",
          label: "Nama Paket",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        // {
        //   prop: 'user',
        //   label: 'Nama Order',
        //   resizable: true,
        //   sortable: false,
        //   minWidth: 100
        // },
        {
          prop: "user",
          label: "Jama'ah",
          resizable: true,
          align: "left",
          sortable: false,
          minWidth: 200,
        },
        {
          prop: "nomor_va",
          label: "VA Nomor",
          resizable: true,
          align: "center",
          sortable: false,
          minWidth: 150,
        },
        {
          prop: "status",
          label: "Status",
          align: "center",
          resizable: true,
          sortable: false,
          minWidth: 100,
        },
        {
          prop: "user_nik",
          label: "NIK",
          resizable: true,
          align: "left",
          sortable: false,
          minWidth: 200,
        },
        {
          prop: "phone_number",
          label: "Nomor Handphone",
          resizable: true,
          align: "center",
          sortable: false,
          minWidth: 150,
        },
      ],
      fake_filter: {
        datepicker: [
          typeof this.$route.query.start_date !== "undefined"
            ? new Date(this.$route.query.start_date)
            : null,
          typeof this.$route.query.end_date !== "undefined"
            ? new Date(this.$route.query.end_date)
            : null,
        ],
      },
      filter: {
        pilgrim:
          typeof this.$route.query.pilgrim !== "undefined"
            ? this.$route.query.pilgrim
            : null,
        user_id:
          typeof this.$route.query.user_id !== "undefined"
            ? this.$route.query.user_id
            : null,
        phone_number:
          typeof this.$route.query.phone_number !== "undefined"
            ? this.$route.query.phone_number
            : null,
        nomor_va:
          typeof this.$route.query.nomor_va !== "undefined"
            ? this.$route.query.nomor_va
            : null,
        start_date:
          typeof this.$route.query.start_date !== "undefined"
            ? this.$route.query.start_date
            : null,
        end_date:
          typeof this.$route.query.end_date !== "undefined"
            ? this.$route.query.end_date
            : null,
        role: this.$store.getters["account/accountData"].role,
        package_id:
          typeof this.$route.query.package_id !== "undefined"
            ? this.$route.query.package_id
            : null,
      },
      dataParams: [
        "pilgrim",
        "user_id",
        "phone_number",
        "nomor_va",
        "start_date",
        "end_date",
        "status",
        "package_id",
      ],
    };
  },
  beforeMount() {
    this.$router.push({ query: { ...this.$route.query } }).catch(() => {});
  },
  mounted() {
    this.getPackage();
  },
  methods: {
    doExportExcel: function() {
      orderModel.doExportExcel(this.$route.query);
    },
    doPrintPaymentInvoice: function(order_no) {
      orderModel.printPaymentInvoice(order_no);
    },
    throwData: function(id, value) {
      let title = "";
      let currentRole = this.whoAmI();

      if (value == 1) {
        if (currentRole == "customer service") title = "ke Back Office";
        else if (currentRole == "back office") title = "ke Manifest";
        else title = "";

        title = "Anda  yakin melanjutkan data ini " + title;
      } else if (value == 0) {
        if (currentRole == "back office") title = "ke Customer Service";
        else if (currentRole == "manifest") title = "ke Back Office";
        else title = "";

        title = "Anda yakin mengembalikan data ini " + title;
      }

      this.$swal
        .fire({
          title,
          text: "Pastikan semua data telah benar",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Simpan",
        })
        .then((result) => {
          console.log("masuk");
          if (result.isConfirmed) {
            orderModel
              .assignToNextStep(value, id)
              .then((resp) => {
                console.log(resp);
                this.$store.dispatch(
                  "notification/success",
                  "Order Berhasil diperbaharui."
                );
                this.show = false;
                this.$nextTick(() => {
                  this.show = true;
                });
              })
              .catch((err) => {
                this.$store.dispatch("notification/error", err);
                this.$router.push("/order");
              });
          }
          return;
        });
    },
    whoAmI: function(role = null) {
      if (role == null) return this.$store.getters["account/accountData"].role;
      return this.$store.getters["account/accountData"].role == role;
    },
    print: function(data) {
      window.open(
        process.env.VUE_APP_API_BASE_URL + "export/order/" + data.order_no
      );
    },
    clearForm() {
      this.$nextTick(() => {
        this.fake_filter.datepicker = null;
        this.filter = {
          pilgrim: null,
          user_id: null,
          phone_number: null,
          nomor_va: null,
          start_date: null,
          end_date: null,
          status: null,
          package_id: null,
        };
        setTimeout(() => {
          this.doFilter();
        }, 100);
      });
    },
    doFilter() {
      if (
        this.fake_filter.datepicker !== null &&
        this.fake_filter.datepicker !== undefined &&
        this.fake_filter.datepicker[0] !== null &&
        this.fake_filter.datepicker[1] !== null
      ) {
        this.filter.start_date = this.$moment(
          this.fake_filter.datepicker[0]
        ).format("YYYY-MM-DD");
        this.filter.end_date = this.$moment(
          this.fake_filter.datepicker[1]
        ).format("YYYY-MM-DD");
      } else {
        this.filter.start_date = null;
        this.filter.end_date = null;
      }
      this.$refs.dataTable.doFilter(this.filter);
    },
    getPackage() {
      packageModel
        .list({
          limit: 10000,
        })
        .then((res) => {
          res.data.forEach((d) => {
            this.options.packages.push({
              value: d.id,
              text: d.name,
            });
          });
        });
    },
  },
};
</script>
